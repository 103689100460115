import axios from '../plugins/axios'
import { ref, reactive } from 'vue'
import { addToCart } from '@/core/cart'
import { showNotif } from '@/core/utility'
import router from '@/router/index'

export const flashSales = ref(null)
export const loading = reactive({
  flashSale: false,
  flashSaleDetail: false
})
export const flashSaleBranches = ref(null)

export const getFlashSale = async () => {
  try {
    loading.flashSale = true
    const { data } = await axios.get('app/flashsale_data')

    if (data.success === true) {
      flashSales.value = data.data
      return {
        status: true,
        message: 'Sukses'
      }
    }
    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error('Error dari flashSale get flashSale', error)
    throw error
  } finally {
    loading.flashSale = false
  }
}

export const getDetailFlashSale = async (id) => {
  try {
    loading.flashSaleDetail = true
    flashSaleBranches.value = null // null kan lagi dulu
    const { data } = await axios.get('app/flashsale_detail_new', {
      params: {
        id_flashsale: id
      }
    })

    if (data.success === true) {
      if (data.data[0]?.jenis === 'KODEPXFREE') {
        flashSaleBranches.value = data.data[0]?.cabang
      }
      return {
        status: true,
        message: 'Sukses',
        data: data.data
      }
    }
    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error('Error dari flashSale get flashSale', error)
    throw error
  } finally {
    loading.flashSaleDetail = false
  }
}

export const prepareToCart = async (param) => {
  const result = await addToCart(param)
  if (result.status) {
    // Trigger toast
    await showNotif({
      status: 'success',
      message: result.message
    })
    // Arahkan ke halaman selanjutnya
    return router.push({
      name: 'Keranjang'
    })
  } else {
    let message = result.message
    if (message === 'login') {
      message = 'Silahkan login terlebih dahulu'
      router.push({
        name: 'Login'
      })
    }
    // Trigger toast
    showNotif({
      status: 'error',
      message: message
    })
  }
}

export const buyFlashSale = async function (idFlashSale) {
  try {
    loading.flashSale = true

    // get detail
    const flashSale = await getDetailFlashSale(idFlashSale)
    if (flashSale.data[0].jenis === 'PAKET') {
      prepareToCart({
        id_dt: flashSale.data[0].id_dt,
        kode: flashSale.data[0].kode,
        nama: flashSale.data[0].nama,
        harga: flashSale.data[0].harga_netto,
        group: flashSale.data[0].nama_group,
        id_flashsale: flashSale.data[0].id_flashsale
      })
      return {
        status: true,
        message: 'Sukses'
      }
    } else if (flashSale.data[0].jenis === 'KODEPXFREE') {
      //
    } else {
      const { data } = await axios.get('app/flashsale_sesi')

      if (data.success === true) {
        flashSales.value = data.data
        return {
          status: true,
          message: 'Sukses'
        }
      }
      return {
        status: false,
        message: data.message
      }
    }
  } catch (error) {
    console.error('Error dari flashSale get flashSale', error)
    throw error
  } finally {
    loading.flashSale = false
  }
}
