<template>
  <!-- Modal -->
  <div class="modal modal-flash-sale fade"
  id="modal-flash-sale"
  tabindex="-1"
  aria-labelledby="pilih cabang" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Pilih Cabang</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div v-if="loadingClaimFlashSale" class="modal-body">
          <Loading/>
        </div>
        <div v-else class="modal-body">
          <div class="row">
            <div class="col-md-4">
              <div class="input-group mb-3 rounded-pill">
                <input
                  type="text"
                  class="form-control bg-transparent"
                  placeholder="Cari cabang...."
                  name="search"
                  v-model="keyword"
                  autocomplete="off">
                <span class="input-group-text bg-transparent border-0">
                    <div
                      class="box-icon"
                      type="button"
                      @click="keyword = ''"
                    >
                      <i class="ri-close-circle-fill fs-14 color-neutral-700"></i>
                    </div>
                </span>
                </div>
            </div>
          </div>
          <div class="row">
            <div
              v-for="(data, index) in filteredFlashSaleBranch"
              class="col-md-4 mb-md-3"
              :key="index">
              <a
                href="javascript:void(0)"
                class="branch rounded d-flex justify-content-between align-items-center py-2 mb-md-2"
                >
                <div class="branch-group d-flex align-items-center">
                  <div class="icon-wrapper bg-neutral-200 d-flex align-items-center justify-content-center rounded-circle">
                    <img
                      :src="require('@/assets/icon/location.png')"
                      alt=""
                    />
                  </div>
                  <div class="branch-group-info d-flex flex-column ps-3">
                    <p class="branch-name fw-bold fs-14 lh-18 color-neutral-900 mb-0">
                      {{ data.nama_cabang }}
                    </p>
                  </div>
                </div>
                <div class="branch-group d-flex align-items-center">
                  <input
                    type="radio"
                    name="branch"
                    class="input-select-branch"
                    :checked="data.id === selectedFlashSaleBranch?.id"
                    @click="selectBranch(data)"
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-warning text-white col py-2" :disabled="!selectedFlashSaleBranch || loadingClaimFlashSale" @click="buy()">Lanjutkan</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
</template>

<script>
import { flashSaleBranches } from '@/core/flashSale'
import { ref, computed } from 'vue'
import axios from '@/plugins/axios'
import { user, token } from '@/core/auth'
import { showNotif } from '@/core/utility'
import Loading from '@/components/utility/Loading'
import { Modal } from 'bootstrap'
import { useRouter } from 'vue-router'

export default {
  components: {
    Loading
  },
  props: ['selectedFlashSaleId'],
  setup (props) {
    const selectedFlashSaleBranch = ref(null)
    const keyword = ref('')
    const loadingClaimFlashSale = ref(false)
    const router = useRouter()

    const filteredFlashSaleBranch = computed(() => {
      return flashSaleBranches.value ? flashSaleBranches.value.filter((data) => {
        return keyword.value.toLowerCase().split(' ').every(v => data.nama_cabang.toLowerCase().includes(v))
      }) : []
    })

    const selectBranch = (branch) => {
      selectedFlashSaleBranch.value = branch
    }

    const buy = async () => {
      try {
        loadingClaimFlashSale.value = true
        const { data } = await axios.post('app/klaim_flashsale', {
          id_flashsale: props?.selectedFlashSaleId,
          cabang: selectedFlashSaleBranch.value.id,
          device: 'WEB'
        }, {
          headers: {
            id_user: user.value.id,
            token: token.value
          }
        })

        if (data.success === true && data?.data?.kode_daftar) {
          router.push({
            name: 'ProfilOrderDetail',
            params: {
              id_order: data?.data?.kode_daftar
            }
          })
        } else {
          // Trigger toast
          showNotif({
            status: 'error',
            message: data.message
          })
        }
      } catch (error) {
        console.error('Error klaim flashsale', error)
        showNotif({
          status: 'error',
          message: error.response?.data?.message ?? error
        })
        throw error
      } finally {
        const modal = Modal.getOrCreateInstance(document.querySelector('#modal-flash-sale'))
        modal.hide()
        loadingClaimFlashSale.value = false
        selectedFlashSaleBranch.value = null
      }
    }

    return {
      flashSaleBranches,
      selectedFlashSaleBranch,
      filteredFlashSaleBranch,
      keyword,
      buy,
      selectBranch,
      loadingClaimFlashSale
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-flash-sale {
  z-index: 10000;
}

.branch {
  box-shadow: 0px -1px 0px 0px #F2F2F2CC inset;

  .icon-wrapper {
    width: 48px;
    height: 48px;
  }

  .branch-group-info {
    gap: 4px;

    .branch-address {
      @media (max-width: 540px) {
        display: block;
        width: 239px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .input-select-branch {
    width: 24px;
    height: 24px;
  }

  .input-select-branch:checked {
    accent-color: #007A98;
  }
}

.input-group {
  border: 1px solid #E2E8F0;

  .form-control, .input-group-text {
    height: 34px;
  }

  .form-control:focus{
    box-shadow: none;
  }

  .form-control::placeholder {
    color: #A0AEC0;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
  }
}
</style>
